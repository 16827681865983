<template>
    <div class="sorder">
        <headBack>
            <template v-slot:title>
                <div class='title'>
                    提交订单
                </div>
            </template>
        </headBack>
        <div class='header_cont'></div>
        <div class='base'>
            <div class='top'>
                <img src='https://static.yihu365.cn/img/h5Img/assets/img/home/insurance_top.png' @click='router.push({name:"insurance"})'/>
            </div>
            <div class='title'>基本信息</div>
            <van-cell :value="checkShowAddress?checkShowAddress:'请选择上门地址'" is-link @click='addressShowactive'
                      :value-class='checkShowAddress?"valueColor":""'>
                <template #title>
                    <span class="custom-title">上门地址</span>
                </template>
            </van-cell>
            <van-cell :value="serviceTime?serviceTime:'请选择服务时间'" is-link @click='timeShow = true'
                      :value-class='serviceTime?"valueColor":""'>
                <template #title>
                    <span class="custom-title">服务时间</span>
                </template>
            </van-cell>
            <van-cell :value="checkShowpatients?checkShowpatients:'请选择患者信息'" is-link @click='patientsShowactive'
                      :value-class='checkShowpatients?"valueColor":""'>
                <template #title>
                    <span class="custom-title">患者信息</span>
                </template>
            </van-cell>
        </div>
        <div class="base" v-if="!route.query.serviceType">
            <div class="bgao_txt">
                <div>邮寄检验报告<span>10元</span></div>
                <div>
                    <van-checkbox v-model="checkedBg" checked-color="#07c160"/>
                </div>
            </div>
            <van-cell
                    class="yj"
                    v-if="checkedBg"
                    :value="checkShowAddressYj?checkShowAddressYj:'请选择邮寄地址'"
                    is-link
                    @click='addressShowactiveYj'
                    :value-class='checkShowAddressYj?"valueColor":""'>
                <template #title>
                    <span class="custom-title">邮寄地址</span>
                </template>
            </van-cell>
        </div>
        <div class="base">
<!--            <div class="tbao_txt">-->
<!--                <span>医护到家将免费为您投保</span> <img src="https://static.yihu365.cn/img/h5Img/assets/img/home/pinan.png"-->
<!--                                              @click="router.push({name:'insurancePage'})" alt="">-->
<!--            </div>-->
        </div>
      <div class="" style="margin-left:16px;display: flex;align-items: flex-start;font-size: 14px;margin-top: 10px;" >
        <div style="width:26px;">
           <van-checkbox checked-color="#00C291" shape="square" v-model="checks"></van-checkbox>
        </div>
        <div style="display: flex;flex:1;flex-wrap: wrap">
          <div style="margin-left: 4px;border:1px solid red;display: block">下单即视为同意</div>
          <a style="color:#00C291" @click="toPage('goumaixieyi')">《购买协议》</a>
          <a style="color:#00C291" @click="toPage('luyinxieyi')">《录音信息收集协议》</a>
        </div>

      </div>
        <div style='margin-top:100px;'></div>
        <div class='btn_btm_cont'>
            <div class='btn_inner'>
                <div class='money'><span class='small'>合计：¥</span>{{totle}}</div>
                <van-button type="success" color='#00C291' class='submit_btn' round @click='submitOrder()'>提交订单
                </van-button>
            </div>
        </div>


        <van-popup
                v-model:show="addressShow"
                closeable
                position="bottom"
        >
            <div class='concel_title'>选择地址</div>
            <div class='address_cont'>
                <div class='address'
                     v-for='(item,index) in addressList'
                     :key='index'
                     @click='checkAddress(item.ID)'
                >
                    <div>
                        <!-- <van-checkbox v-model="checkAddressId == item.ID?true:false" checked-color='#00C291' icon-size='16px'></van-checkbox> -->
                        <van-checkbox v-model="item.check" checked-color='#00C291' icon-size='16px'></van-checkbox>
                    </div>
                    <div class='address_top'>
                        <div class='provinces'>
                            <div><img src='https://static.yihu365.cn/img/h5Img/assets/img/my/default.png' class='default' v-if='item.ISDEFAULT'/>{{item.ADDRESS}}
                            </div>
                            <!-- <img src='https://static.yihu365.cn/img/h5Img/assets/img/my/edit.png' class='icon_edit'/> -->
                        </div>
                        <div class='detailed'>
                            {{item.DOORPLATE}}-{{item.ADDRESS_DETAIL}}
                        </div>
                    </div>
                </div>

            </div>
            <div class='btn_content'>
                <van-button type="success" color='#00C291' class='submit_btn' round block @click='addaddressbtn'>添加新地址
                </van-button>
            </div>
        </van-popup>

        <!--邮寄地址-->
        <van-popup
                v-model:show="addressShowYj"
                closeable
                position="bottom"
        >
            <div class='concel_title'>选择邮寄地址</div>
            <div class='address_cont'>
                <div class='address'
                     v-for='(item,index) in addressList'
                     :key='index'
                     @click='checkAddressYj(item.ID)'
                >
                    <div>
                        <!-- <van-checkbox v-model="checkAddressId == item.ID?true:false" checked-color='#00C291' icon-size='16px'></van-checkbox> -->
                        <van-checkbox v-model="item.check" checked-color='#00C291' icon-size='16px'></van-checkbox>
                    </div>
                    <div class='address_top'>
                        <div class='provinces'>
                            <div><img src='https://static.yihu365.cn/img/h5Img/assets/img/my/default.png' class='default' v-if='item.ISDEFAULT'/>{{item.ADDRESS}}
                            </div>
                            <!-- <img src='https://static.yihu365.cn/img/h5Img/assets/img/my/edit.png' class='icon_edit'/> -->
                        </div>
                        <div class='detailed'>
                            {{item.DOORPLATE}}-{{item.ADDRESS_DETAIL}}
                        </div>
                    </div>
                </div>

            </div>
            <div class='btn_content'>
                <van-button type="success" color='#00C291' class='submit_btn' round block @click='addaddressbtn'>添加新地址
                </van-button>
            </div>
        </van-popup>


        <van-popup
                v-model:show="patientsShow"
                closeable
                position="bottom"
        >
            <div class='concel_title'>选择患者</div>
            <div class='address_cont'>
                <div class='address' v-for='(item,index) in patientsList' :key='index' @click='checkpatients(item.ID)'>
                    <div>
                        <van-checkbox v-model="item.check" checked-color='#00C291' icon-size='16px'></van-checkbox>
                    </div>
                    <div class='address_top'>
                        <div class='provinces'>
                            <div><img src='https://static.yihu365.cn/img/h5Img/assets/img/my/default.png' class='default'/>{{item.REAL_NAME}}
                                {{item.MOBILE}}
                            </div>
                            <!-- <img src='https://static.yihu365.cn/img/h5Img/assets/img/my/edit.png' class='icon_edit'/> -->
                        </div>
                        <div class='detailed'>
                            {{item.SEX?'女':'男'}} {{item.AGE}}岁 {{item.RELATIONSHIP}}
                        </div>
                    </div>
                </div>


            </div>
            <div class='btn_content'>
                <van-button type="success" color='#00C291' class='submit_btn' round block @click='addbingrenbtn'>添加新患者
                </van-button>
            </div>
        </van-popup>
        <van-popup
                v-model:show="timeShow"
                position="bottom"
        >
            <!--            <div class='concel_title'>选择服务时间</div>-->
            <!--            <doorTime @submitTime='submitTime'></doorTime>-->

            <van-picker
                    ref="timePicker"
                    cancel-button-text=" "
                    title="请选择服务时间"
                    :columns="columns"
                    @confirm="onConfirm"
                    @change="onChange"
            />
        </van-popup>
    </div>
</template>
<script>
    import {onMounted, ref, computed} from "vue"
    import {useRouter, useRoute} from 'vue-router'
    import {useStore} from 'vuex'
    import loginApi from '@axios/login'
    import jsonp from '@assets/js/jsonp'
    import {Toast} from 'vant';
    import moment from 'moment'

    export default {
        name: 'bloodSorder',
        components: {},
        setup() {

            let router = useRouter()
            const store = useStore()
            const route = useRoute()

            const checkedBg = ref(false)
            let addressShow = ref(false);
            let addressShowYj = ref(false);
            let patientsShow = ref(false);
            let timeShow = ref(false);
            const timePicker = ref(null);
            //虚拟选择套餐列表
            // let packageList = ref([]);
            //地址信息
            let addressList = ref([]);
            //患者信息
            let patientsList = ref([]);
            //选择的地址ID
            let checkAddressId = ref('');
            let checkAddressIdYj = ref('');//邮寄地址id
            //患者ID
            let patientsId = ref('');
            let patientsCard = ref('');
            //时间string
            let serviceTime = ref('');
            //开始时间
            let timerStar = ref('');
            let timerEnd = ref('');
            const columns= ref([]);


          //护士任务参数
          let Recommenduserphone = route.query.Recommenduserphone ?  route.query.Recommenduserphone : ''
          let batchNumber = route.query.batchNumber ?  route.query.batchNumber : ''
            const columns1 = [ // 第一列
                {
                    values: [
                        moment().add(2, 'days').format("MM月DD日"),
                        moment().add(3, 'days').format("MM月DD日"),
                        moment().add(4, 'days').format("MM月DD日"),
                        moment().add(5, 'days').format("MM月DD日"),
                        moment().add(6, 'days').format("MM月DD日"),
                        moment().add(7, 'days').format("MM月DD日"),
                    ],
                    defaultIndex: 0,
                },
                // 第二列
                {
                    values: ['07:00', '08:00', '09:00'],
                    defaultIndex: 0,
                },
                {
                    values: ['08:00', '09:00', '10:00'],
                    defaultIndex: 0,
                },
            ];


            const setTime = () => {
                const times = []
                let i = 0
                while (times.length < 15){
                    i++;
                    let week = moment().add(i, 'days').isoWeekday()
                    if(week == 6 || week == 7){
                        continue;
                    }
                    let timeStr =moment().add(i, 'days').format("MM月DD日")
                    times.push(timeStr)
                }
                return times.slice(4,14);
            }


            const columns2=[
                {
                    values: setTime(),
                    defaultIndex: 0,
                },
                // 第二列
                {
                    values: ['07:00', '08:00', '09:00', '10:00', '11:00', '12:00', '13:00', '14:00', '15:00', '16:00', '17:00', '18:00', '19:00'],
                    defaultIndex: 0,
                },
                {
                    values: [ '08:00', '09:00', '10:00', '11:00', '12:00', '13:00', '14:00', '15:00', '16:00', '17:00', '18:00', '19:00', '20:00'],
                    defaultIndex: 0,
                },
            ]

            if(route.query.serviceType){
                columns.value=columns2
            }else{
                columns.value=columns1
            }
            const onConfirm = (value, index) => {
                // Toast(`当前值: ${value}, 当前索引: ${index}`);
                let timeStr = '';
                let momentYear = '';
                value.forEach((ele, index) => {
                    if (index == 0) {
                        momentYear = moment(ele, 'MM-DD-YYYY').format('YYYY-MM-DD');
                    }
                    if (index == 1) {
                        timerStar.value = momentYear + ' ' + ele + ':00';
                    }
                    if (index == 2) {
                        timeStr += '- '
                        timerEnd.value = momentYear + ' ' + ele + ':00';
                    }
                    timeStr += ele + ' ';
                })
                serviceTime.value = timeStr;
                timeShow.value = false;
            };
            const onChange = (value, index) => {
                if(columns.value == columns1){
                    if (index == 1) {
                        let colindex = timePicker.value.getColumnIndex(1)//对应列的下标
                        if (colindex == 0) {
                            timePicker.value.setColumnValues(2, ['08:00', '09:00', '10:00'])
                        }
                        if (colindex == 1) {
                            timePicker.value.setColumnValues(2, ['09:00', '10:00'])
                        }
                        if (colindex == 2) {
                            timePicker.value.setColumnValues(2, ['10:00'])
                        }
                    }
                }else{
                    if (index == 1) {
                        let colindex = timePicker.value.getColumnIndex(1)//对应列的下标
                        timePicker.value.setColumnIndex(2,colindex)
                    }
                    if (index == 2) {
                        let colindex = timePicker.value.getColumnIndex(2)//对应列的下标
                        timePicker.value.setColumnIndex(1,colindex)
                    }
                }

            };


            const getAdress = (type) => {
                let formData = new FormData();
                formData.append('data', JSON.stringify({
                    function: 'selectAddressByUserId',
                    _from: 'h5',
                    token: store.state.token,
                    userid: store.state.uid + '',
                    login_userId_base: store.state.uid + '',
                    _validate: '1',
                    version: store.state.version,
                    newversion: store.state.newversion,
                }))

                formData.append('encryption', false)

                loginApi.loginAction(formData).then((res) => {
                    res.data.forEach(element => {
                        if (element.ISDEFAULT) {
                            if (type == 1) {
                                checkAddressId.value = element.ID
                            } else {
                                checkAddressIdYj.value = element.ID
                            }

                            element.check = true
                        } else {
                            element.check = false
                        }

                    });
                    addressList.value = res.data;
                    setCityCody();
                })
            }

            let checkShowAddress = computed(() => {
                let str = '';
                addressList.value.forEach((ele) => {
                    if (ele.ID == checkAddressId.value) {
                        str = ele.DOORPLATE + '-' + ele.ADDRESS_DETAIL
                    }
                })
                return str;
            })

            //邮寄
            let checkShowAddressYj = computed(() => {
                let str = '';
                addressList.value.forEach((ele) => {
                    if (ele.ID == checkAddressIdYj.value) {
                        str = ele.DOORPLATE + '-' + ele.ADDRESS_DETAIL
                    }
                })
                return str;
            })


            let checkShowpatients = computed(() => {
                let str = '';
                patientsList.value.forEach((ele) => {
                    if (ele.ID == patientsId.value) {
                        str = ele.REAL_NAME + '/'
                        if (ele.SEX) {
                            str += '女/'
                        } else {
                            str += '男/'
                        }

                        str += ele.AGE;
                        str += '/';
                        str += ele.RELATIONSHIP
                    }
                })
                return str;
            })

            const checkAddress = (id) => {
                checkAddressId.value = id;
                addressList.value.forEach((ele) => {
                    if (ele.ID == id) {
                        ele.check = true
                    } else {
                        ele.check = false
                    }
                })
                addressShow.value = false;
                setCityCody();
            }

            const checkAddressYj = (id) => {
                checkAddressIdYj.value = id;
                addressList.value.forEach((ele) => {
                    if (ele.ID == id) {
                        ele.check = true
                    } else {
                        ele.check = false
                    }
                })
                addressShowYj.value = false;
                //setCityCody();
            }


            const checkpatients = (id) => {
                patientsId.value = id;
                patientsList.value.forEach((ele) => {
                    if (ele.ID == id) {
                        ele.check = true
                    } else {
                        ele.check = false
                    }
                })
                patientsShow.value = false;
            }

            getAdress(1);



            const getPatients = () => {
                let formData = new FormData();
                formData.append('data', JSON.stringify({
                    function: 'selectPatientInfoById',
                    _from: 'h5',
                    token: store.state.token,
                    userid: store.state.uid + '',
                    login_userId_base: store.state.uid + '',
                    _validate: '1',
                    version: store.state.version,
                    newversion: store.state.newversion,
                }))

                formData.append('encryption', false)

                loginApi.loginAction(formData).then((res) => {
                    res.data.forEach(element => {
                        if (element.ISDEFAULT) {
                            patientsId.value = element.ID
                           patientsCard.value = element.IDCARD_NO
                            element.check = true
                        } else {
                            element.check = false
                        }
                    });
                    patientsList.value = res.data;
                })
            }
            getPatients();


            let totle = computed(() => {
                let sumtotle = parseFloat(route.query.price);

                if (checkedBg.value && !route.query.serviceType) {
                    sumtotle += 10;
                }
                // if(hasFee.value){
                //     sumtotle += 50 //添加服务费
                // }

                return sumtotle
            })
            //选中地址的城市code
            let citycode = '';
            const setCityCody = () => {
                let active_adress = {}
                addressList.value.forEach((ele) => {
                    if (ele.ID == checkAddressId.value) {
                        active_adress = ele;
                    }
                })

                jsonp(`https://api.map.baidu.com/reverse_geocoding/v3/?ak=uoyXSaX5BoRlqIFLTUy0zW5KgjTPZ85q&output=json&location=${active_adress.LATITUDE},${active_adress.LONGITUDE}&callback=baiduCallBack`).then((res) => {
                    console.log(res.result.cityCode)
                    citycode = res.result.cityCode;
                })

            }


             let checks = ref(false)
            //检查用户是否进行了所有操作
            const checkform = () => {
                if (!checkAddressId.value) {
                    Toast('请选择上门地址');
                    return false;
                }
                if (!serviceTime.value) {
                    Toast('请选择服务时间');
                    return false;
                }
                if (!patientsId.value) {
                    Toast('请选择患者');
                    return false;
                }
                if (checkedBg.value && !checkAddressIdYj.value) {
                    Toast('请选择邮寄地址');
                    return false;
                }
                if(!checks.value){
                  Toast("请先勾选购买协议")
                  return false;
                }
                return true;
            }


            const submitOrder = () => {
                //页面操作参数整理
                let check = checkform();
                if (!check) {
                    return;
                }

                let formData = new FormData();
                let active_adress = {}
                addressList.value.forEach((ele) => {
                    if (ele.ID == checkAddressId.value) {
                        active_adress = ele;
                    }
                })
                let patientstel = '';
                let patientsname = '';
                patientsList.value.forEach((ele) => {
                    if (ele.ID == patientsId.value) {
                        patientstel = ele.MOBILE;
                        patientsname = ele.REAL_NAME;
                    }
                })
                // uoyXSaX5BoRlqIFLTUy0zW5KgjTPZ85q
                formData.append('data', JSON.stringify({
                    function: 'subscribe',
                    token: store.state.token,
                    userid: store.state.uid,
                    userName: store.state.uid,
                    targetUserId: "",
                    roleType: route.query.roleCode,//目标用户ID
                    service: route.query.code,
                    orderType: '1',
                    price: totle.value + '',
                    // description : message.value,
                    // longitude: active_adress.LONGITUDE,//经度  此处少经纬度
                    // latitude: active_adress.LATITUDE,//纬度
                    // serviceTimeStart: serviceTime.value,//预约服务开始时间
                    // serviceTimeEnd: serviceTime.value,//预约服务结束时间
                    address: checkAddressId.value + '',
                    payStatus: '-1',
                    checkStatus: '0',
                    patientArchivesId: patientsId.value + '',
                   registrationIdCard : patientsCard.value + '',
                    sid: store.state.sid,
                    cityCode: citycode + '',
                    // professionCode : packagerealList.value[packcheckIndex.value].code,
                    picture1: '',
                    picture2: '',
                    // times : packagerealList.value[packcheckIndex.value].times,
                    // needTools : checked.value == 2?'1':'0',
                    // isHasTool : checked.value == 2?'0':'1',
                    isHasMedicina: '',
                    offerPrice: totle.value,
                    addedServicePrice: '',
                    // prescriptionPic : chufang.value[0]?chufang.value[0].url:'',
                    // medicinePic : yaoPin.value[0]?yaoPin.value[0].url:'',
                    casePic: '',
                    liveCondPic: '',
                    //耗材包相关
                    // pkgId : checked.value != 2?'':consumptionDetail.value[pakradio.value].pkgId + '',
                    // pkgNum : checked.value != 2?'':consumptionDetail.value[pakradio.value].pkgNum + '',
                    //夜间费用
                    // extraPrice : hasFee.value?'50':'',
                    // extraPaymentType : hasFee.value?'2':'0',//费用为0就是空 不为0就是2
                    patientMobile: patientstel + '', //患者电话

                    _from: 'h5',
                    version: store.state.version,
                    newversion: store.state.newversion,
                    source: 'h5_users_002',
                    jumpMark: store.state.jumpMark,

                    //11项新产品
                    consigneeCity: '',
                    consigneeLongitude: active_adress.LONGITUDE,//经度  此处少经纬度
                    consigneeLatitude: active_adress.LATITUDE,//纬度
                    serviceTimeStart: timerStar.value,//预约服务开始时间
                    serviceTimeEnd: timerEnd.value,//预约服务结束时间
                    consigneeCitycode: citycode + '',
                    consigneeAddress: checkShowAddressYj.value,
                    consigneeMobile: patientstel + '',
                    consigneeProvince: '',//邮寄省
                    consigneeName: patientsname + '', //邮寄姓名
                    subService:route.query.scdSvCode,

                  //护士任务参数
                  Recommenduserphone:Recommenduserphone + '',
                  batchNumber:batchNumber + ''

                }))

                formData.append('encryption', false)

                loginApi.loginAction(formData).then((res) => {


                    router.push({
                        name: 'pay',
                        query: {
                            fee: totle.value,
                            orderId: res.data.orderId,
                            roleCode: '002',
                            serviceCode: route.query.code,
                        }
                    })
                })
            }


            const addaddressbtn = () => {
                addressShow.value = false;
                addressShowYj.value = false;
                router.push({
                    name: 'addAddress'
                })
            }

            const addbingrenbtn = () => {
                patientsShow.value = false;
                router.push({
                    name: 'addPatients'
                })
            }

            const addressShowactive = (ev) => {
                getAdress(1);
                addressShow.value = true;
            }
            const addressShowactiveYj = (ev) => {
                getAdress(2);
                addressShowYj.value = true;
            }

            const patientsShowactive = () => {
                getPatients();
                patientsShow.value = true;
            }
            const toPage =(url)=> {
              router.push({
                name: url
              })
            }

            return {
                toPage,
                addressShowactive,
                addressShowactiveYj,
                patientsShowactive,
                addbingrenbtn,
                addaddressbtn,
                checkedBg,
                // pakclick,
                // pakradio,
                // servicId,
                submitOrder,
                totle,
                // packagerealList,
                // yaoPin,
                // chufang,
                // afterReadYaopin,
                // consumptionDetail,
                // packcheckIndex,
                // onCancel,
                // onConfirm,
                checkShowpatients,
                checkpatients,
                patientsList,
                serviceTime,
                // submitTime,
                checkShowAddress,
                checkShowAddressYj,
                checkAddress,
                checkAddressYj,
                checkAddressId,
                checkAddressIdYj,
                addressList,
                timeShow,
                patientsShow,
                addressShow,
                addressShowYj,
                // specificationsShow,
                // checked,
                router,
                route,
                // afterReadChufang,
                // message,
                columns,
                onChange,
                onConfirm,
                timerStar,
                timerEnd,
                timePicker,
              checks
            }
        },

    }
</script>
<style rel="stylesheet/scss" lang="scss" scoped>
    .btn_content {
        padding: 30px;
    }

    .top {
        // padding:25px 32px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 20px;

        img {
            width: 100%;
        }

    }

    .address_cont {
        padding: 30px;
        padding-top: 0;
        max-height: 800px;
        overflow-y: scroll;

        .address {
            padding: 0 15px;
            background: #fff;
            border-radius: 10px;
            margin-bottom: 25px;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            border-bottom: 1px solid #EDEDED;

            .address_top {
                padding-top: 40px;
                padding-bottom: 25px;
                padding-left: 25px;
                width: 100%;
            }
        }

        .provinces {
            display: flex;
            justify-content: space-between;
            // align-items: center;
            font-size: 28px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #313131;
            padding-right: 30px;

            & > div {
                display: flex;
                // align-items: center;
            }

            .default {
                width: 60px;
                height: 30px;
                margin-right: 20px;
                position: relative;
                top: 5px;
            }

            .icon_edit {
                width: 34px;
                height: 34px;
                position: relative;
                top: 5px;
            }
        }
    }

    .concel_title {
        font-size: 30px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #333333;
        text-align: center;
        line-height: 100px;
        height: 100px;
        border-bottom: 1px solid #EDEDED;
    }

    .specifications_cont {
        padding: 30px;

        .specifications_btn {
            padding-top: 48px;
            padding-bottom: 24px;
        }

        .specifications {
            font-size: 26px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #333333;
            padding: 25px 0;
            border-bottom: 1px solid #EDEDED;

            .specifications_num {
                display: flex;
                justify-content: space-between;
                align-items: center;
            }

            .chose_specifications {
                display: flex;
                flex-wrap: wrap;

                div {
                    padding: 10px 25px;
                    background: rgba(0, 0, 0, 0.05);
                    border-radius: 8px;
                    font-size: 24px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #333333;
                    margin-top: 18px;
                    margin-right: 15px;
                }

                .active_specifications {
                    border: 1px solid #00C291;
                    background: #F4FFFA;
                    color: #00C291;
                }
            }
        }

        .specifications_top {
            font-size: 24px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #333333;
            display: flex;
            justify-content: flex-start;
            align-items: flex-end;
            border-bottom: 1px solid #EDEDED;
            padding-bottom: 30px;

            .money {
                color: #FF6161;
                font-size: 40px;
                font-weight: 500;

                .small {
                    font-size: 28px;
                    margin: 0 5px;
                }

            }

            .goods {
                width: 170px;
                height: 170px;
                border-radius: 10px;
                margin-right: 20px;
            }
        }

    }

    .sorder {
        height: 100%;
        background: #f0f0f0;
        overflow: scroll;
    }

    .base {
        // padding:20px 35px;
        border-radius: 10px;
        overflow: hidden;
        background: #fff;
        margin-top: 20px;
        margin-left: 30px;
        margin-right: 30px;

        ::v-deep(textarea) {
            padding: 15px;
            background: #F7F7F7;
            border-radius: 10px;
        }

        ::v-deep(.van-uploader__upload) {
            border: 1px dashed #999;
        }

        .title {
            font-size: 34px;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color: #313131;
            padding: 20px 35px;
        }
    }

    .up_cont {
        padding: 20px 30px;
        display: flex;
        justify-content: flex-start;
    }

    .custom-title {
        display: flex;
        justify-content: flex-start;
        align-items: center;

        .title_name {
            position: relative;
            top: 4px;
        }
    }

    .btn_btm_cont {
        width: 100%;
        position: fixed;
        bottom: 0;
        left: 0;
        padding: 20px 0;
        display: flex;
        justify-content: flex-end;
        background: #fff;
        z-index: 999;
        border-top: 1px solid #f5f5f5;

        .btn_inner {
            padding: 0 30px;
            display: flex;
            justify-content: flex-end;
            align-items: center;
        }

        .order_btn {
            font-size: 26px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #999999;
            height: 50px;
            padding: 0 22px;
            line-height: 50px;
            border-radius: 25px;
            border: 1px solid #999999;
            margin-left: 20px;
        }

        .money {
            margin-right: 40px;
            color: #FF6161;
            font-size: 40px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
        }

        .small {
            font-size: 28px;
            margin: 0 5px;
        }

        .submit_btn {
            width: 200px;
            height: 80px;
            line-height: 80px;
          white-space: nowrap;
        }
    }

    .detailed {
        font-size: 28px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #979797;
        margin-top: 18px;
    }

    .radio_cell {
        ::v-deep(.van-cell__title) {
            width: 600px !important;

        }

        ::v-deep(.van-radio) {
            margin-right: 20px;
            width: 50px;
        }

        ::v-deep(.van-cell__value) {
            flex: 0 !important;
        }
    }


    .bgao_txt {
        display: flex;
        justify-content: space-between;
        font-size: 26px;
        padding: 20px 35px;
        color: #313131;
    }

    .tbao_txt {
        font-size: 26px;
        padding: 20px 35px;
        color: #323232;

        span {
            vertical-align: middle;
            margin-right: 10px;
        }

        img {
            width: 100px;
            vertical-align: middle;
        }
    }
</style>
