import { render } from "./bloodSorder.vue?vue&type=template&id=42e94587&scoped=true"
import script from "./bloodSorder.vue?vue&type=script&lang=js"
export * from "./bloodSorder.vue?vue&type=script&lang=js"

import "./bloodSorder.vue?vue&type=style&index=0&id=42e94587&rel=stylesheet%2Fscss&lang=scss&scoped=true"
script.render = render
script.__scopeId = "data-v-42e94587"
/* hot reload */
if (module.hot) {
  script.__hmrId = "42e94587"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('42e94587', script)) {
    api.reload('42e94587', script)
  }
  
  module.hot.accept("./bloodSorder.vue?vue&type=template&id=42e94587&scoped=true", () => {
    api.rerender('42e94587', render)
  })

}

script.__file = "src/views/home/sorder/bloodSorder.vue"

export default script